import React from 'react';
import PricingTable from 'components/tables/PricingPlansTable';
import MainLayout from 'components/layouts/MainLayout'



function PricingPlans() {
    return (
        <MainLayout>
            <PricingTable />
        </MainLayout>
    )
}

export default PricingPlans;
import React from 'react';
import './PricingPlansTableStyles.scss'
import { FaCheck, FaTimes } from "react-icons/fa"
import DeepLinkButton from "components/buttons/DeepLinkButton";
import { StoreContext } from 'store';

const PricingTable = ({ children }) =>
  <div className="brochure-pricing-table brochure-bootstrap">
    <div className="maincontainer">
      <section>
        <div class="container py-4">
          <div class="row text-center align-items-end">
            {children}
          </div>
        </div>
      </section>
    </div>
  </div>


const PricingTableHeader = ({ children }) =>
  <header class="text-center mb-5">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        {children}
      </div>
    </div>
  </header>


// const DEFAULT_BUTTON_STYLE = { backgroundColor: 'transparent', color: 'black', width: '18rem' }
const PricingSlot = ({ children, priceText, cadenceText, title, titleClassName, buttonText, buttonHref, buttonClassName, buttonTarget }) =>
  <div class="col-lg-4 mb-5 mb-lg-0">
    <div class="p-5 rounded-lg shadow bg-grey-tint">
      <h1 style={{ fontSize: '1.2rem' }} class={"h6 text-uppercase font-weight-bold mb-4 " + titleClassName}>{title}</h1>
      <h2 class="h1 font-weight-bold">{priceText}<span class="text-small font-weight-normal ml-2">{cadenceText}</span></h2>
      <div class="custom-separator my-4 mx-auto bg-primary"></div>
      <ul class="list-unstyled my-4 text-small text-left">
        {children}
      </ul>
      <DeepLinkButton className={buttonClassName} href={buttonHref} buttonTarget={buttonTarget}>{buttonText}</DeepLinkButton>
    </div>
  </div>

const ICON_TYPES_MAP = {
  'check': ({ iconColor }) => <FaCheck color={iconColor} />,
  'cross': () => <FaTimes color="red" />
}

const PricingDetail = ({ children, iconType, iconColor, strikethrough }) =>
  <li class="mb-3">
    {iconType && ICON_TYPES_MAP[iconType]({ iconColor })}
    <span style={{ paddingLeft: '5px' }}>
      {(strikethrough) ? <del>{children}</del> : children}
    </span>
  </li>



function PricingPlansTable() {
  const store = React.useContext(StoreContext)

  return (
    <PricingTable>
      <PricingTableHeader>
        <h1 className="txt-color-blue txt-size-1-5xl">Pricing plans</h1>
        <p>Powerful tools in every package</p>
      </PricingTableHeader>
      
      <div style={{ display: "flex", justifyContent: "space-around", flexDirection: store.isMobile ?  "column" :  "row"  } }>

      
        <PricingSlot cadenceText={<b>Priced by complexity</b>} title="Project"
          titleClassName="txt-color-blue" buttonClassName="btn-size-m bg-blue txt-color-white"
          buttonText="Contact us" buttonHref="mailto: info@forsightdigital.com">
          <PricingDetail iconType="check" iconColor="rgb(54,120,254)">View <b>access</b></PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(54,120,254)">Edit <b>access</b></PricingDetail>
          <PricingDetail>1 <b>location</b></PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(54,120,254)">Unlimited <b>scenarios</b></PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(54,120,254)">Instant feedback</PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(54,120,254)">Most <b>tools available</b></PricingDetail>
          <PricingDetail>Up to 6 <b>collaborators</b></PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(54,120,254)">Download data</PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(54,120,254)">Download reports</PricingDetail>
          <PricingDetail iconType="cross" strikethrough>Upload data to generate scenario</PricingDetail>
          <PricingDetail iconType="cross" strikethrough>Admin panel access for multiple users</PricingDetail>
        </PricingSlot>
        <PricingSlot cadenceText={<b>Business pricing</b>} title="Enterprise"
          titleClassName="txt-color-dark-blue" buttonClassName="btn-size-m bg-dark-blue txt-color-white"
          buttonText="Contact us" buttonHref="mailto: info@forsightdigital.com">
          <PricingDetail iconType="check" iconColor="rgb(17, 40,200)">View <b>access</b></PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(17, 40,200)">Edit <b>access</b></PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(17, 40,200)">Unlimited <b>locations</b></PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(17, 40,200)">Unlimited <b>scenarios</b></PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(17, 40,200)">Instant feedback</PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(17, 40,200)">All <b>tools available</b></PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(17, 40,200)">Unlimited  <b>collaborators</b></PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(17, 40,200)">Download data</PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(17, 40,200)">Download reports</PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(17, 40,200)">Upload data to generate scenario</PricingDetail>
          <PricingDetail iconType="check" iconColor="rgb(17, 40,200)">Admin panel access for multiple users</PricingDetail>
        </PricingSlot>
      </div>
    </PricingTable>
  )
}

export default PricingPlansTable;
